import request from '@/utils/request'

export function getDisclaimer(data = {}) {
  return request({
    url: '/disclaimer/',
    method: 'post',
    data
  })
}

export function getDisclaimerStatement(data = {}) {
  return request({
    url: '/disclaimer/statement',
    method: 'post',
    data
  })
}
