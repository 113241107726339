<!--
 * @Author: diaowangtao
 * @Date: 2020-08-21 17:22:29
 * @Description: 免责声明
-->
<template>
  <div class="privacy">
    <my-banner :bg_banner="bg_banner" v-if="bg_banner"></my-banner>
    <div class="w1200">
      <my-caption :title="title" class="title" v-if="title.name"></my-caption>
      <div class="content" v-html="$options.filters.sLang(content)"></div>
    </div>
  </div>
</template>

<script>
import myCaption from '@/components/myCaption/index.vue'
import myBanner from '@/components/myBanner/index.vue'
import { getDisclaimer, getDisclaimerStatement } from '@/api/disclaimer'
export default {
  name: 'privacy',
  components: {
    myCaption: myCaption,
    myBanner: myBanner,
  },
  data() {
    return {
      bg_banner: '',
      title: { name: '', dec: '' },
      content:''
    }
  },
  created() {
    getDisclaimer().then((res) => {
      //console.log(res)
      this.bg_banner = res.coverList[1].oldUrl
    })
    getDisclaimerStatement().then((res) => {
      //console.log(res)
      this.title.name=res.article.title
      this.content = res.article.content
    })
  },
}
</script>

<style lang='scss'>
.privacy {
  .w1200 {
    overflow: hidden;
    padding-bottom: 100px;
    .title {
      margin: 60px 0 40px 0;
    }
    .content {
      color: #303a51;
      text-align: justify;
      line-height: 25px;
      p{
        margin-bottom: 20px;
      }
    }
  }
}
</style>